import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#235789',
        },
        secondary: {
            main: '#C1292E',
        },
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
