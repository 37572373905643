import React, { useMemo } from 'react';
import { FormGrid } from '../../primitives';
import { FieldDefinition, FormControlsForFields } from '../../schemed';
import { RecordEditData } from './useRecordEdit';
import { LinkedRecordSelectorMultiple } from './LinkedRecordSelector';
import { AttributeConfig } from '../useRecordTypeConfig';
import { TagSelector } from './TagSelector';

interface Props {
    data: RecordEditData;
}

const RightColumnFieldTypes = ["mediafile", "json", "textlong", "text-long", "markdown"];
const FancyFieldTypes = ["tag", "tag_multiple", "record_link", "record_link_multiple"];

export const RecordAttributesForm = (props: Props) => {
    const { data, updateAttributes } = props.data;
    const { data: config, attributesSchema, } = props.data.config;

    const { fat, normal, fancyConfigs } = useMemo(
        () => (config.attributes || []).reduce<{ normal: FieldDefinition[], fat: FieldDefinition[], fancyConfigs: AttributeConfig[] }>(
            (r,a) => {
                if(RightColumnFieldTypes.includes(a.fieldtype || "")) {
                    r.fat.push([a.code]);
                } else if(FancyFieldTypes.includes(a.fieldtype || "")) {
                    r.fancyConfigs.push(a);
                } else {
                    r.normal.push([a.code]);
                }
                return r;
            }, { normal: [], fat: [], fancyConfigs: [] }),
            [config.attributes]
        )
    
    return fat.length > 0
        ? (
            <FormGrid columns={fancyConfigs.length ? "1fr 1fr 1fr" : "2fr 1fr"} noMargin forceEvenColumns>
                <FormGrid columns={fancyConfigs.length ? "1fr" : "1fr 1fr"} noMargin>
                    <FormControlsForFields
                        data={data.attributes || {}}
                        onChange={(o,c) => updateAttributes(c)}
                        schema={attributesSchema}
                        fields={normal}
                        />
                </FormGrid>

                {!!fancyConfigs.length &&
                  <FormGrid columns="1fr" noMargin>
                    {fancyConfigs.map(f => {
                      switch(f.fieldtype) {
                        case "record_link":
                        case "record_link_multiple":
                          return (
                            <LinkedRecordSelectorMultiple
                              key={f.code}
                              config={f}
                              data={data.attributes || {}}
                              onChange={(o,c) => updateAttributes(c)}
                              />
                          );
                        case "tag":
                        case "tag_multiple":
                          return (
                            <TagSelector
                              key={f.code}
                              config={f}
                              data={data.attributes || {}}
                              onChange={(o,c) => updateAttributes(c)}
                              />
                          )
                        default:
                          return null;
                      }
                    })}
                  </FormGrid>}

                <FormGrid columns="1fr" noMargin>
                    <FormControlsForFields
                        data={data.attributes || {}}
                        onChange={(o,c) => updateAttributes(c)}
                        schema={attributesSchema}
                        fields={fat}
                        />
                </FormGrid>

                
            </FormGrid>
        )
        : (
            <FormGrid noMargin>
                <FormControlsForFields
                    data={data.attributes || {}}
                    onChange={(o,c) => updateAttributes(c)}
                    schema={attributesSchema}
                    fields={normal}
                    />
            </FormGrid>
        );
}
